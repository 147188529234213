import * as React from 'react'
import PricingBanner from '../components/PricingBanner'
import Layout from '../components/Layout'
import SupportItem from '../components/SupportItem'
import Seo from "../components/Seo"

const UsecasePage = () => {
    return (
        <Layout>
            <Seo 
                title="Use cases"
                description="Places where we can help with your workload"
            />
            <div className='flex items-center justify-center'>
                <h1 className="my-8 text-4xl font-bold text-center md:my-12 text-rickshaw-blue">
                    Use cases
                </h1>
            </div>
            <ul className="mx-4 border-t-2 border-b-2 divide-y-2 lg:mx-auto lg:max-w-screen-lg divide-rickshaw-blue border-rickshaw-blue">
                <SupportItem title="Marketing">
                    <p>
                       Popular platforms like <a className="underline text-rickshaw-blue" href="https://www.xero.com">Xero</a> integrate with Stripe, matter of fact there are <a className="underline text-rickshaw-blue" href="https://stripe.com/en-au/partners/apps-and-extensions">hundreds if not thousands of platforms</a> that partner with Stripe.
                    </p>
                    <p className="mt-2">
                        It's never been easier for a business to accept payments online or start an online shop. Since these platforms try and cater
                        to everyone it can be difficult for them to setup custom communication.
                    </p>
                    <p className="mt-2">
                        Rickshaw can help bridge this gap, it provides an elegant way to create custom communication without having to 
                        invest in custom development or work around the platforms that you run your business on.
                    </p>
                </SupportItem>
                <SupportItem title="Software as a Service (SaaS)">
                    <p>
                        Most SaaS products setup infrastructure that processes emails and SMS notifications in the background.
                    </p>
                    <p className="mt-2">
                        Like many developers choose managed databases or other such infrastructure to ease their workload, Rickshaw
                        can help by taking away the communication load away from your development schedule.
                    </p>
                    <p className="mt-2">
                        We are a small developer cohort and would love to talk to you to meet your custom needs.
                    </p>
                </SupportItem>
                <SupportItem title="Compliance (e.g Not for Profits)">
                    <p>
                        Over COVID-19 many not for profits reached out to us to get stated with hosted payment pages.
                        For the most part Stripe is rather easy to get up and running with accepting payments.
                    </p>
                    <p className="mt-2">
                        Where these organisations got unstuck was sending formal communication when say a donation was received.
                        A custom built solution turned out to be rather expensive. Rickshaw was born from such customer feedback.
                    </p>

                </SupportItem>
                <SupportItem title="Internal notifications">
                    <p>
                        One of the most common use cases for Rickshaw is notifying someone in the organisation of payment events.
                    </p>
                    <p className="mt-2">
                        Rickshaw has an inbuilt address book that you can add internal email addresses and mobile phone numbers.
                        You can then have Rickshaw send a team member notifications on events like payouts.
                    </p>

                </SupportItem>

            </ul>

            <PricingBanner />
        </Layout>
    );
};

export default UsecasePage;
